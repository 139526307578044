@font-face {
	font-family: "NunitoFont";
	src: url("./assets/Font/Nunito/Nunito-VariableFont_wght.ttf");
}

body {
	font-family: "NunitoFont" !important;
}

html {
	font-family: "NunitoFont" !important;
}

/* p {
	padding: 0 !important;
	margin: 0 !important;
} */

.e-control {
	font-family: "NunitoFont" !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary,
.e-control.e-btn.e-lib.e-flat {
	font-size: 16px;
	font-weight: 500;
}

.e-float-input.e-control-wrapper {
	height: 30px;
	min-height: 30px;
}

.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus {
	box-shadow: unset !important;
}

.e-ddl.e-input-group .e-input-value,
.e-ddl.e-input-group .e-input-value:focus {
	font-size: 14px;
	margin: 0;
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
}

.flag-image {
	margin-top: 0px;
	height: 25px;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
	border: 1.5px solid rgb(156, 163, 175);
	border-radius: 6px;
	margin-top: -1px;
}

[aria-disabled="true"] {
	/* You can apply any styles to visually represent the disabled state */
	pointer-events: none; /* Disable user interactions */
	color: #aaa; /* Change text color to indicate disabled state */
	cursor: not-allowed; /* Show "not-allowed" cursor on hover */
	opacity: 0.5;
}

@media print {
	* {
		overflow: visible !important;
		background-color: white;
	}
}
